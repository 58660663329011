import { up } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { isServer } from 'utils/runtimeUtils'
import { useUserDeviceData } from 'services/UserDeviceContext'
import useOnMount from './useOnMount'

// This should stay false forever in the server, and change to true in client only
// Used to prevent a flicker after the first render. Once we hydrated we never want
// to use the server results again.
let mounted = false

export default function useIsMediumScreen() {
  const [didMount, setDidMount] = useState(mounted)
  const device = useUserDeviceData()

  const themeContext = useContext(ThemeContext)
  const match = useBreakpoint(up('md'))

  // This makes it so if there is a difference between the browser and the server about
  // whether the screen is large, we will use the server version in the first render.
  // This prevent possible hydration errors, since the client needs to render the exact
  // same thing the server did. On the second render this will flicker to client calculation.
  useOnMount(() => {
    setDidMount(true)
    mounted = true
  })

  if (isServer() || !didMount) return device.isDesktop

  if (match == null) {
    return window.screen.width >= themeContext.breakpoints.md
  }

  return match
}
